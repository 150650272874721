/* eslint-disable */
import HttpService from '../HttpService';
export default class Assessment{
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`survey`);
  }

  async FindAllAssessment() {
    return await this._httpService.get('/assessment/find_all_sent_and_enabled');
  }

  async FindAllMandatoryAssessment() {
    return await this._httpService.get('/assessment/find_all_mandatory_and_sent_and_enabled');
  }

  async FindAllSentAndEnabled() {
    return await this._httpService.get('/assessment/find_all_sent_and_enabled');
  }

  async findFeedbackById(id) {
    return await this._httpService.get(`/feedback/${id}`);
  }
}

