<template>
  <v-container style="height: 100%" class="bg-glass-effect-lighter px-3 pt-15" fluid>
    <v-row v-if="isLoaded">
      <v-col cols="12" class="px-5 pb-0 my-0 pl-6">
        <h1 style="font-size: 32px"  class="main-title">Perfil Saúde</h1>
      </v-col>
    </v-row>

    <v-row v-if="!isEmpty" justify="center">
      <v-col cols="12">
        <v-card elevation="8" rounded="lg" class="mx-3 mb-3 mt-5 card-layout" v-for="(assessment, index) in assessments" :key="assessment.id">
          <v-card-title class="main-subtitle">
            <v-row>
              <v-col class="pl-2" cols="1">
                <i class="far fa-clipboard pl-2" style="color: var(--v-primary); font-size: 24px;"></i>
              </v-col>
              <v-col cols="11">
                <h3 style="word-break: normal;color: var(--v-primary);">
                  {{ assessment && assessment.name ? assessment.name : (' Questionário ' + index) }}
                </h3>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="mt-3">
            <v-row>
              <v-col cols="12" class="mb-3 mt-3">
                <GenericBtn :btn-props="{ block: true, small: true, color: 'secondary' }"
                            :onClick="() => $router.push({name: 'assessment-answer', params: {assessmentId: assessment.id, assessmentName: assessment.name}})">
                  Acessar
                </GenericBtn>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <v-row v-else class="px-5">
      <v-col cols="12">
        Não existem questionários disponíveis.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GenericBtn from "@/components/generic-btn/index"
import {mapMutations} from "vuex";
import moment from 'moment-timezone';
import Assessment from "@/services/survey/Assessment";

export default {
  name: "assessmentList",
  components: {
    GenericBtn
  },
  data: () => ({
    assessmentMandatory: false,
    assessments: [],
    isEmpty: false,
    isLoaded: false
  }),
  beforeCreate() {
    this._assessmentService = new Assessment()
  },
  async created () {
    this.assessmentMandatory = this.$route.query ? this.$route.query.assessmentMandatory : false;

    this.loading(true)
    await this.loadAssessments()
    this.loading(false)
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    getFormattedDate(date){
      return moment(date).format('DD/MM/YYYY')
    },
    async loadAssessments(){
      if(this.assessmentMandatory) {
        await this._assessmentService.FindAllSentAndEnabled()
          .then(response => {
            if(response.data && response.data.length){
              this.assessments = response.data;
              this.isLoaded = true;
            }else{
              this.saveCompletedAndShowException(null);
            }
          })
          .catch(() => {
            this.showmsg({text: "Ocorreu um erro ao tentar carregar os questionários. Por favor, entre em contato com o administrador.", type: "error"});
            this.isLoaded = true;
            this.saveCompletedAndShowException('Ocorreu um erro ao tentar carregar os questionários. Por favor, entre em contato com o administrador.');
          });

      } else {

        await this._assessmentService.FindAllAssessment()
          .then(response => {
            if(response.data && response.data.length){
              this.assessments = response.data
            }else{
              this.isEmpty = true;
            }
          })
          .catch(() => {
            this.saveCompletedAndShowException('Ocorreu um erro ao tentar carregar os questionários. Por favor, entre em contato com o administrador.');
          })
          .finally(() => {
            this.isLoaded = true
          });
      }
    },
    saveCompletedAndShowException(message) {
      sessionStorage.setItem('assessmentMandatoryCompleted', true);
      this.$router.push({ name: 'home', params: { messageException: message } });
    }
  }
}
</script>

<style scoped>
.card-layout {
  border-radius: 12px !important;
  margin-bottom: 30px;
}
</style>


